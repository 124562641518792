import React from "react";
import "./styles.css";
import { industriesSectors } from "../../constants/content";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

function IndustriesSectors() {
  const navigate = useNavigate();
  return (
    <div className="industriesSectors">
      <div className="container">
        <Fade direction="up">
          <center className="mb-4">
            <h1>Industries and Sectors</h1>
            <p>Our diverse interests cut across different key sectors:</p>
          </center>
        </Fade>
        <div className="row">
          {industriesSectors.map((el, i) => {
            const dynamicBackground = {
              background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${el.imageURL}) no-repeat center center fixed`,
              backgroundSize: "cover",
            };
            return (
              <div
                key={i}
                className="col-md-6 sector-item"
                onClick={() => navigate(`/sector/${i}#`)}
              >
                <div className="card">
                  <div className="header" style={dynamicBackground}>
                    {el.logo && (
                      <div className="logos-container">
                        <img src={el.logo} className="logo-ind" />
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <h5 className="text-bold">{el.title}</h5>
                    <p className="description">{el.description}</p>
                  </div>
                  <div className="d-flex flex-wrap">
                    {el.category.map((cat, c) => {
                      return (
                        <div className="category-item" key={c}>
                          <p>{cat}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default IndustriesSectors;
