import React, { useEffect, useState } from "react";
import "./styles.css";
import { industriesSectors } from "../../../constants/content";
import { useParams } from "react-router-dom";

function IndustriesSectorView() {
  const { index } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (index !== undefined) {
      setData(industriesSectors[index]);
    }
  }, [index]);

  if (!data) return;

  const dynamicBackground = {
    background: `linear-gradient(rgba(0, 0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${data.imageURL}) no-repeat center center fixed`,
    backgroundSize: "cover",
  };

  return (
    <div className="industriesSector">
      <div className="header-cover-mage" style={dynamicBackground}></div>

      <div className="container">
        <div className="col-md-12">
          <div className="mb-3">
            <h5 className="text-bold">{data.title}</h5>
            <p className="description">{data.description}</p>
          </div>
          <div className="d-flex flex-wrap mb-5">
            {data.category.map((cat, c) => {
              return (
                <div className="category-item" key={c}>
                  <p>{cat}</p>
                </div>
              );
            })}
          </div>
          {data?.clients?.length > 0 && (
            <div>
              <h5 className="mb-3 bold-text">Clients</h5>
              <div className="row">
                {data?.clients?.map((el, i) => {
                  return (
                    <div className="col-md-2" key={i} id={"#" + i}>
                      <div className="client-item">
                        <div className="card">
                          <div className="card-body">
                            <div className="image">
                              <img src={el.logo} />
                            </div>
                            <div>
                              <span>{el.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default IndustriesSectorView;
