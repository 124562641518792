import React from "react";
import Navbar from "../components/navbar";
import IndustriesSectorView from "../components/industriesSectors/view";
import Contact from "../components/contact";
import Footer from "../components/footer";
import IndustriesSectors from "../components/industriesSectors";

function SectorScreen() {
  return (
    <>
      <Navbar /> <IndustriesSectorView />
      {/* <IndustriesSectors /> */}
      <Contact />
      <Footer />
    </>
  );
}

export default SectorScreen;
