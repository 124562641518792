import React from "react";

function Input({
  label,
  placeholder,
  isRequired,
  value,
  onChange,
  error,
  multi,
}) {
  const props = {
    className: "form-control form-control-lg",
    value,
    placeholder,
    onChange,
  };
  return (
    <div className="form-group">
      <label>
        {label && <span>{label}</span>}
        {isRequired && <span className="text-danger">*</span>}
      </label>
      {multi ? (
        <textarea rows={5} className="form-control"></textarea>
      ) : (
        <input {...props} />
      )}

      {error && <span className="text-danger">{error}</span>}
    </div>
  );
}

export default Input;
