import React from "react";
import Navbar from "../components/navbar";
import Introduction from "../components/introduction";
import IdentifyingChallenges from "../components/identifyingChallenges";
import ApproachesToSolutions from "../components/approachesToSolutions";
import IndustriesSectors from "../components/industriesSectors";
import Team from "../components/team";
import Contact from "../components/contact";
import Footer from "../components/footer";

function LandingScreen() {
  return (
    <>
      <section
        id="introduction"
        style={{ backgroundColor: "var(--text-light)" }}
      >
        <Introduction />
      </section>

      <Navbar />

      <section id="identifyingChallenges">
        <IdentifyingChallenges />
      </section>
      <section id="approachesToSolutions">
        <ApproachesToSolutions />
      </section>
      <section id="industriesAndSectors">
        <IndustriesSectors />
      </section>
      {/* <section id="team">
        <Team />
      </section> */}
      <section id="contact">
        <Contact />
      </section>
      <section id="footer">
        <Footer />
      </section>
    </>
  );
}

export default LandingScreen;
