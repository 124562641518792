import React, { useEffect } from "react";
import Logo from "../logo";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button";
import "./styles.css";

function Navbar() {
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", onScroll, {
      passive: true,
    });
    onScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = () => {
    const scrollY = window.pageYOffset;

    const viewportHeight = window.innerHeight * 1; // 90vh
    const navbar = document.getElementById("appNavbar");

    if (navbar && navbar.classList) {
      let navbarClassList = navbar.classList;

      if (scrollY >= viewportHeight) {
        if (navbarClassList) {
          navbarClassList.add("fixed-top");
        }
      } else {
        navbarClassList.remove("fixed-top");
      }
    }
  };

  return (
    <nav id="appNavbar" class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <Link class="navbar-brand" to="/">
          <Logo />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/#identifyingChallenges">
                Challenges
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/#approachesToSolutions"
                tabindex="-1"
                aria-disabled="true"
              >
                Solutions
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/#industriesAndSectors"
                tabindex="-1"
                aria-disabled="true"
              >
                Industries & Sectors
              </Link>
            </li>
            <li className="nav-item">
              <Button
                text="Contact"
                className="btn-lg"
                onClick={() => navigate("#contact")}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
