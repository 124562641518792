import React from "react";
import "./styles.css";
import { identifyingChallenges } from "../../constants/content";
import { Fade } from "react-awesome-reveal";

function IdentifyingChallenges() {
  return (
    <div className="identifyingChallenges">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Fade direction="up">
              <div className="header">
                <h1>Identifying Challenges</h1>
              </div>
            </Fade>
            <Fade direction="up" cascade>
              {identifyingChallenges.map((el, i) => {
                return (
                  <div key={i} className="item">
                    <div className="count">
                      <span>{i + 1}</span>
                    </div>
                    <div style={{ flex: 1 }}>
                      <h5 className="bold-text">{el.title}</h5>
                      <p>{el.description}</p>
                    </div>
                  </div>
                );
              })}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IdentifyingChallenges;
