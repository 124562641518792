// import useToast from "@/hooks/useToast";
import { AxiosError } from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

export default function toastMessage(type = "error", error) {
  try {
    let errorMessage = "An error occurred";

    if (typeof error === "string") {
      errorMessage = error;
    } else if (error instanceof AxiosError) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        errorMessage = error.response.data.error;
      }
    } else if (error && error.message) {
      errorMessage = error.message;
    } else if (error) {
      errorMessage = error;
    }

    const config = {
      position: "top-right",
      duration: 7000,
    };

    if (type === "error") {
      toast.error(errorMessage, {
        ...config,
        style: {
          background: "#ffcfcf",
          color: "#f50707",
        },
      });
    } else if (type === "success") {
      toast.success(errorMessage, {
        ...config,
        style: {
          background: "#d5f7d6",
          color: "green",
        },
      });
    } else {
      toast.error(errorMessage, {
        ...config,
        style: {
          background: "#a6d7ff",
          color: "#078ece",
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}
