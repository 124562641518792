import React from "react";

function Button({
  text,
  onClick,
  appearance = "primary",
  loading,
  className,
  type,
}) {
  const props = {
    text,
    onClick,
    appearance,
    className,
  };
  return (
    <button
      {...props}
      className={`btn btn-${appearance} ${className}`}
      disabled={loading}
      type={type}
    >
      {loading ? "Please wait..." : text}
    </button>
  );
}

export default Button;
