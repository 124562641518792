import React from "react";
import "./styles.css";
import Logo from "../logo";
import { navigation, socialMedia } from "../../constants/content";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="open">
            <div className="row">
              <div className="col-md-3">
                <div className="mb-3">
                  <Logo />
                </div>
                <p>
                  As passionate agents of change, we firmly believe in the power
                  of transformation and actively work to drive impactful
                  improvements in Africa.
                </p>
              </div>
              <div className="col-md-6">
                <div className="col-md-4 mx-auto">
                  <ul className="list-group">
                    {navigation.map((el, i) => {
                      return (
                        <li key={i} className="list-group-item">
                          <Link
                            style={{ color: "var(--text-dark)" }}
                            to={el.url}
                          >
                            {el.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <ul className="list-group">
                  {socialMedia.map((el, i) => {
                    return (
                      <li key={i} className="list-group-item">
                        <Link style={{ color: "var(--text-dark)" }} to={el.url}>
                          {el.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-closing">
          <div className="container">
            <p>
              {new Date().getFullYear()} © Limitless Africa Investment - All
              right reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
