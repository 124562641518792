import teamImage from "../assets/logo.png";
import kobe from "../assets/kobe.jpeg";
import limitlessApps from "../assets/limitless-apps.jpg";
import blackBox from "../assets/black-box.jpeg";
import limitlessAgency from "../assets/limitless-agency.png";
import olga from "../assets/olga.jpeg";

export const introduction = [
  {
    title: "Transforming Africa’s Future",
    description:
      "At Limitless Africa Investment Ltd, we are committed to driving impactful change across the continent. By focusing on innovation and continuous growth, we transform challenges into opportunities.",
  },
  {
    title: "Unlocking Africa’s Potential",
    description:
      "Our mission is to unlock the latent power of Africa by fostering ventures that contribute to the continent’s development, creating a brighter future for all.",
  },
  // {
  //   title: "A Visionary Team",
  //   description:
  //     "Our founders are passionate leaders from diverse backgrounds, united in their commitment to catalyzing business and societal transformation throughout Africa.",
  // },
];

export const identifyingChallenges = [
  {
    title: "Funding Gaps",
    description:
      "Entrepreneurs across Rwanda, Kenya, and beyond often struggle to access the necessary funding to bring their bold ventures to life.",
  },
  {
    title: "Local Barriers",
    description:
      "Navigating regulatory, infrastructural, and market-specific hurdles poses significant challenges for businesses looking to grow in Africa.",
  },
];

export const approachesToSolutions = [
  {
    title: "Exploration and Research",
    description:
      "We conduct in-depth research to identify untapped opportunities and understand the market landscape.",
  },
  {
    title: "Unlocking Opportunities",
    description:
      "By identifying visionary ideas and enterprises with high growth potential, we transform challenges into springboards for exponential success.",
  },
  {
    title: "Support for Growth",
    description:
      "We provide the strategic guidance and financial support necessary to bridge gaps and overcome barriers, enabling businesses to thrive.",
  },
];

export const industriesSectors = [
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093489/event.avif",
    title: "Limitless Agency Ltd",
    logo: limitlessAgency,
    category: [
      "Market Intelligence",
      "Event Management",
      "Branding",
      "Advertising",
    ],
    description: `(formerly PackAd Marketing Services Ltd)- Market Intelligence, Event
Management, Branding & Advertising: Our passion has been and still is to bring value to our clients and their customers
through great ideas with the aim of making it easier for all to make informed decisions. In
today's rapidly evolving business landscape, we offer services that empower businesses to
navigate market dynamics, identify growth opportunities, and stay ahead of the competition.
Through rigorous research, data analysis, and strategic insights, the company equips
businesses with the knowledge and insights needed to drive growth, optimize operations, and
maximize profitability in an increasingly competitive marketplace.`,
    clients: [
      {
        name: "Skol Brewery Ltd",
        logo: "",
      },
      {
        name: "Fastnet",
        logo: "",
      },
      {
        name: "DY Business Group & DY Solutions Ltd,",
        logo: "",
      },
      {
        name: "CEC Construction",
        logo: "",
      },
      {
        name: "EPC Africa",
        logo: "",
      },
      {
        name: "Euphoria Restaurant",
        logo: "",
      },
      {
        name: "AgroPY Ltd",
        logo: "",
      },
      {
        name: "Zoe Mining & Trading Co Ltd",
        logo: "",
      },
      {
        name: "Simba Supermarket",
        logo: "",
      },
      {
        name: "Evyap",
        logo: "",
      },
      {
        name: "Solid Africa",
        logo: "",
      },
    ],
  },
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093489/industries.jpg",
    logo: limitlessApps,
    title: "Limitless Apps Ltd",
    category: ["Tech", "Fin-Tech"],
    description: `Tech and Fin-Tech:
In the ever-evolving realm of technology and finance, Limitless Apps Ltd is at the forefront of
driving innovation and digital transformation. With a keen focus on fostering digital solutions, the
company is poised to revolutionize industries and enhance efficiency across various sectors.
From fintech innovations that promote financial inclusion to innovative tech solutions that drive
productivity and growth, Limitless Apps is committed to harnessing the power of technology to
create meaningful change and drive economic progress. For example, during the COVID
lockdowns, we were inspired to build an e-commerce platform we were inspired to build an e-commerce platform called Murugo. The word
“Murugo” means “Home” in Kinyarwanda. This platform targets small businesses and encouraged them to take their businesses online. Among other solutions we have provided to
various clients is a data collection application for Skol Brewery Ltd.`,

    client: [
      {
        title: "MURUGO",
        logo: "",
      },
      {
        title: "SKOL Data Collection App",
        logo: "",
      },
    ],
  },
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093632/photo-1511452885600-a3d2c9148a31.avif",
    title: "Limitless Properties Ltd",
    category: ["Real Estate"],
    description: `In the realm of Real Estate, Limitless Properties Ltd seeks transformative projects that redefine
urban landscapes and elevate living standards in Rwanda. From pioneering residential
developments to innovative commercial spaces, the company'ss real estate ventures reflect a
commitment to quality, sustainability, and community-centric design. By investing in strategic
real estate projects, Limitless Properties Ltd aims to address housing needs, stimulate
economic growth, and create vibrant, inclusive communities.`,
  },
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093712/premium_photo-1670315264849-8cb4a1b1342e.avif",
    logo: blackBox,
    title: "Blackbox Solutions LTD",
    category: ["Consultancy firm"],
    description: `Blackbox Solutions was founded out of necessity to solve the common issues that
hinder achievement of strategic objectives for different organizations and institutions. The
founders of Blackbox Solutions Ltd and associated consultants have a wide range of experience
in different sectors not only in Rwanda but also in different countries in Africa. Our shared
experiences have witnessed gaps in service delivery and regulations across Africa. We mitigate
the usual predicament faced by institutions and organizations to source for expertise that
understand local context and dynamics.`,
  },
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093760/photo-1507089947368-19c1da9775ae.avif",
    title: "SAFI Industries Ltd",
    category: ["Industrial Kitchen Equipment Manufacture", "Supplies"],
    description: `As
a leading supplier of industrial kitchen equipment, the company plays a vital role in supporting
the hospitality and food service industry across Africa. With a comprehensive range of high-
quality equipment and solutions, the company caters to the diverse needs of hotels, restaurants,
catering businesses, and institutional kitchens. From state-of-the-art kitchen appliances to
efficient food service solutions, SAFI Industries Ltd is dedicated to providing cutting-edge
equipment that enhances operational efficiency, improves food safety standards, and elevates
culinary experiences.`,
  },
  {
    imageURL:
      "https://res.cloudinary.com/dwqhmch33/image/upload/v1728093806/photo-1560493676-04071c5f467b.avif",
    title: "Kobe & Duma",
    logo: kobe,
    category: ["Agriculture (Coming soon)"],
    description: `Recognizing the pivotal role of agriculture in Africa'ss economic development, Kobe and Duma
invests in agricultural support ventures that promote sustainability, productivity, and food security.
By supporting agribusinesses and agricultural initiatives, the company aims to unlock the
potential of Africa'ss agricultural sector, drive innovation in farming practices, and enhance
livelihoods for rural communities. From modernizing farming techniques to promoting value-
added agribusiness ventures, Kobe and Duma is committed to fostering a thriving agricultural
ecosystem that powers economic growth and sustains livelihoods.`,
  },
  {
    imageURL: olga,
    title: "OrgaGreen LTD",
    logo: olga,
    category: ["Agriculture", "Bio-Tech"],
    description: `OrgaGreen aims to revolutionize agricultural productivity and economic development in Africa and the Middle East, while simultaneously addressing environmental sustainability and promoting Climate-Positive agricultural practices. The company is dedicated to scaling the nature-based solution, leveraging methane-consuming microbes, known as Mems, to capture methane from Lake Kivu, landfills, manure lagoons, rice paddies and other domestic sources to produce organic fertilizer.`,
  },
];

export const teams = [
  {
    image: teamImage,
    title: "Executive Chairman",
    name: "The Green Innovator",
    bio: `Is a seasoned entrepreneur and executive with a proven track record of
translating strategic plans into impactful initiatives that optimize operational performance and
align with corporate environmental goals. As the Executive Chairman of Limitless Africa
Investment Ltd, he has developed solutions to optimize operational performance in various
areas, including business advisory and investments, financing and investment facilitation,
innovation management, and business outsourcing.

He is actively seeks intra-African business opportunities, particularly those involving green and
innovative technologies that have the potential to make a significant impact on people'ss lives.
He also conducts business evaluations on innovative technologies to assess their potential for
investment, both locally and internationally.
His dedication to sustainable development is exemplified by his long-standing involvement
with Solid'sAfrica, where he has served as an active board member since 2010. Through his
leadership, Solid'sAfrica has achieved significant milestones, including the construction of a
smart, semi-automated kitchen powered by clean and low carbon energy. He has also been a
driving force behind the adoption of climate-smart permaculture techniques on Solid'sAfrica'ss
farms, resulting in increased productivity and resilience.
Recognizing the urgent need to support rural communities, Solid’Africa, supported by him,
launched an initiative to empower 4,500 farmers in Rwanda to embrace regenerative
agricultural practices. This transformative project not only enhances the livelihoods of these
farmers but also contributes to the overall food security and environmental sustainability of the
region.
The current initiative of creating natural fertilizer from methane gas holds immense potential to
revolutionize Rwanda'ss agricultural sector, leveraging Patrick'ss extensive knowledge and
expertise in sustainable development.
With his unparalleled experience in business advisory, investment facilitation, and innovation
management, coupled with his deep understanding of the agriculture sector in Rwanda, Patrick
Nizeyimana is uniquely qualified to spearhead this transformative project. His vision, leadership,
and unwavering commitment to creating positive change make him an invaluable asset in
driving Africa'ss sustainable development forward.`,
  },
  {
    image: teamImage,
    title: "Group Chief Executive Officer",
    name: "The Change Architect",
    bio: `With a career spanning over two decades, Ivan is a self-driven entrepreneur with
accomplishments across various professional fields. Under his leadership, our subsidiary
companies, have been instrumental in spearheading transformative projects that create
transformative change.
Prior to his role at Limitless Africa Investment Ltd, he was the Chairman of the SME Caucus at
the Kenya Association of Manufacturers (KAM) from March 2017 to February 2019. At KAM, he
steered SME policy and advocacy work in alignment with the government’s Manufacturing
Priority Agenda, focusing on initiatives such as the Buy Kenya Build Kenya Initiative, capacity-
building programs, and trade and export development services.
Additionally, his tenure as a Board Member of the at the United Nations Global Compact, Kenya
Chapter from June 2015 to February 2020, allowed him to contribute to the organization'ss
mission of embedding universal business principles and values of good labor practices, human
rights, safeguarding the environment and fighting corruption in all its forms for the benefit of all.
As a result of his efforts, Ivan received commendations from the New York Headquarters for his
efforts on advocating for safeguarding the environment, good governance, increasing the
network’s participant base, and providing guidance in developing fundraising strategies.
In 2012, he co-founded Green Pencils Ltd, a company dedicated to manufacturing
environmentally friendly pencils made from recycled newspapers in Kenya. As Director, he

played a pivotal role in driving the company’s sales and marketing strategies, achieving revenue
growth, and maintaining high quality standards. Additionally, he oversaw the production of road
studs from sustainable materials, further contributing to environmental sustainability and road
safety.
His diverse professional background includes roles in Banking, Finance, Social Enterprise, and
Manufacturing. These roles equipped him with invaluable experience in running business
operations across various industries with relative ease.
With a passion for entrepreneurship and a commitment to driving positive change, Ivan
continues to leverage his expertise and leadership skills to create impactful ventures and
contribute to the sustainable development of communities across the region.`,
  },
  {
    image: teamImage,
    title: "Chief Operations Officer",
    name: "The Strategic Commander",
    bio: `Rt. Capt. Colbert is a retired officer from the Rwanda Defense Forces (RDF), having provided a
distinguished service with many accolades. He has over 29 years of experience in operations,
personnel management, capacity building, and training. Armed with a Master of Social Science,
Colbert has established himself as a versatile professional with a wealth of expertise across
diverse sectors.
Throughout his illustrious career, Colbert has demonstrated an exceptional ability to collaborate
with various stakeholders, including senior government officials, development partners, private
institutions, and the United Nations (UN).
As a Senior Training Facilitator for the United Nations Institute for Training and Research
(UNITAR), he has conducted a remarkable 101 training missions in 18 African countries. His
expertise extends to gender mainstreaming, where he serves as a skilled Gender Advisor,
empowering women and promoting gender equality in all facets of training and operations.
Colbert is committed to professional excellence. While serving as the Operations Manager at
Intersec Security Company Ltd, Rwanda'ss leading security company, his leadership contributed
to the development of enhanced professionalism in providing private security services in
Rwanda. He was directly involved in the Company’s operations, training, and strategic planning.
From serving as a Director of Training in the Rwanda Air Force to fulfilling roles in UN missions
and the Private Sector, Colbert brings a unique perspective to his work, exploring the
intersection of all these sectors. Fluent in multiple languages, including French, English, Swahili,
Kinyarwanda, and Lingala, he possesses the linguistic skills necessary to engage with diverse
communities and facilitate meaningful dialogue. His illustrious career reflects his unwavering
commitment to excellence, professionalism, and service to humanity, thus leaving a lasting
impact on the communities he serves.`,
  },
];

export const contact = {
  title: "Unlock Africa's Potential with Us!",
  description: `Connect with us to explore partnerships, discuss investment strategies, or learn about our initiatives.`,
};

export const navigation = [
  {
    name: "Home",
    url: "/#introduction",
  },
  {
    name: "Industries and Sectors",
    url: "/#industriesAndSectors",
  },
  {
    name: "Contact us",
    url: "/#contact",
  },
  {
    name: "Solutions",
    url: "/#approachesToSolutions",
  },
];

export const socialMedia = [
  {
    name: "Facebook",
    url: "",
    logo: "",
  },
  {
    name: "Twitter",
    url: "",
    logo: "",
  },
  {
    name: "Instagram",
    url: "",
    logo: "",
  },
  {
    name: "Youtube",
    url: "",
    logo: "",
  },
];
