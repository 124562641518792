import React from "react";
import logoImage from "../../assets/logo.png";
import "./styles.css";

function Logo({ width }) {
  return (
    <div className="logo-container">
      <img src={logoImage} alt="Limitless Logo" width={width} />
    </div>
  );
}

export default Logo;
