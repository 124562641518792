import React from "react";
import "./styles.css";
import { approachesToSolutions } from "../../constants/content";
import { Fade } from "react-awesome-reveal";

function ApproachesToSolutions() {
  return (
    <div className="approachesToSolutions">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <Fade direction="up">
              <div className="header">
                <h1>Approaches to Solutions</h1>
              </div>
            </Fade>
            <div className="row">
              <Fade cascade>
                {approachesToSolutions.map((el, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="count">
                        <span>{i + 1}</span>
                      </div>
                      <div style={{ flex: 1 }}>
                        <h5 className="bold-text">{el.title}</h5>
                        <p>{el.description}</p>
                      </div>
                    </div>
                  );
                })}
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproachesToSolutions;
