import React from "react";
import "./styles.css";
import { introduction } from "../../constants/content";
import Carousel from "react-grid-carousel";
import Button from "../button";

function Introduction() {
  return (
    <div className="introduction">
      <Carousel
        cols={1}
        rows={1}
        // showDots
        hideArrow
        loop
        scrollSnap
        autoplay={20000}
        grabCursor
        centererSlides
        dotColorActive="#00a3da"
        gap={50}
      >
        {introduction.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div>
                <div
                  className="intro-item"
                  // style={{ backgroundColor: item.color }}
                >
                  <div className="col-md-4 mx-auto">
                    <div>
                      <h1 className="mb-3">{item.title}</h1>
                      <p className="mb-3 text-disabled">{item.description}</p>
                      <Button
                        text="Explore"
                        className="btn-lg cta-button "
                        onClick={() =>
                          (window.location.href = "#identifyingChallenges")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Introduction;
