import { Route, Routes, Outlet, useLocation } from "react-router-dom";
import LandingScreen from "../screens/LandingScreen";
import SectorScreen from "../screens/IndustrialSectorScreen";
import { useEffect } from "react";

function ScrollToSection() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return null;
}

const routes = [
  {
    path: "/",
    element: <LandingScreen />,
  },
  {
    path: "/sector/:index",
    element: <SectorScreen />,
  },
];

const Navigation = () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
      <ScrollToSection />
    </>
  );
};

export default Navigation;
