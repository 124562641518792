import React, { useRef } from "react";
import "./styles.css";
import Input from "../input";
import Button from "../button";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { contact } from "../../constants/content";
import Logo from "../logo";
import { useFormik } from "formik";
import * as Yup from "yup";
import toastMessage from "../../utils/toastMessage";
import emailjs from "@emailjs/browser";

// Fix Leaflet icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const email_required = "Email address is required";
const name_required = "Name is required";
const message_required = "Message is required";
const invalid_email = "Invalid email address";

function Contact() {
  const position = [-1.9589335388137459, 30.09427850069698];
  const form = useRef();

  const validationSchema = Yup.object({
    name: Yup.string().required(name_required),
    email: Yup.string().email(invalid_email).required(email_required),
    message: Yup.string().required(message_required),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      onSendEmail();
    },
  });

  const onSendEmail = () => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICEID,
        process.env.REACT_APP_EMAILJS_TEMPLATEID,
        form.current,

        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        (result) => {
          toastMessage("success", "SUCCESS! Your message has been sent.");
          // resetForm();
        },
        (error) => {
          toastMessage("error", "FAILED to send the message", error.text);
        }
      );
  };

  return (
    <>
      <div className="contact">
        <div className="container">
          <div className="row">
            <form
              ref={form}
              // onSubmit={formik.handleSubmit}
              className="col-md-6 mx-auto"
            >
              <div className="contact-form">
                <div className="mb-3">
                  <h1 className="mb-3">{contact.title}</h1>
                  <p className="mb-4" style={{ opacity: 0.8 }}>
                    {contact.description}
                  </p>
                </div>
                <div className="mb-3">
                  <Input
                    label="Name"
                    value={formik.values.name}
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target.value)
                    }
                    error={formik.errors.name}
                    isRequired
                    placeholder="Your name"
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <Input
                    label="Email Address"
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue("email", e.target.value)
                    }
                    error={formik.errors.email}
                    isRequired
                    placeholder="Your email address"
                    name="to_name"
                  />
                </div>
                <div className="mb-3">
                  <Input
                    label="Message"
                    value={formik.values.message}
                    onChange={(e) =>
                      formik.setFieldValue("message", e.target.value)
                    }
                    error={formik.errors.message}
                    placeholder="Your message"
                    // multi
                    name="message"
                  />
                </div>
                <div>
                  <Button
                    text="Submit"
                    onClick={formik.handleSubmit}
                    loading={formik.isSubmitting}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MapContainer
        center={position}
        zoom={16}
        scrollWheelZoom={false}
        className="map"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={position}
          ref={(marker) => marker && marker.openPopup()}
        >
          <Popup>
            <div>
              <Logo />
              <br />
              +250789906300 <br />
            </div>
            <br />
            <Button
              text="Direction"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`,
                  "_blank"
                )
              }
            />
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
}

export default Contact;
